<template>
  <div id="terminal_record_component">
    <div class="terminal_record_container">
      <div class="terminal_record_container__item title_container">
        <div class="title_container__item title_status_container">
          <div class="title_status_container__item title">
            <label>Записи экрана</label>
          </div>
          <div v-if="showLoad" class="title_status_container__item">
            <div class="spring-spinner">
              <div class="spring-spinner-part top">
                <div class="spring-spinner-rotator"></div>
              </div>
              <div class="spring-spinner-part bottom">
                <div class="spring-spinner-rotator"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="title_container__item create_button">
          <button
            v-if="online && !anyWaitingTask"
            @click="onCreateRecord"
            class="blue_button"
          >
            Создать
          </button>
          <button v-if="!online || anyWaitingTask" class="gray_button">
            Создать
          </button>
        </div>
      </div>
      <div class="terminal_record_container__item task_status_container">
        <action-bar
          :show="actionBarData.main.show"
          @close-action-bar="resetActionBar('main')"
          :status="actionBarData.main.status"
          :action="actionBarData.main.action"
          :broadDescription="actionBarData.main.broadDescription"
        />
      </div>
      <div v-if="records.length ===0" class="terminal_record_container__item not_found">Не найдено</div>
      <div v-else class="terminal_record_container__item record_container">
        <div
          v-for="(el, i) in records"
          :key="i"
          class="records_container__item record_line_container"
          :class="showNewRecord && i === 0 ? 'selected' : ''"
        >
          <div class="record_line_container__item record_info_container">
            <div class="record_info_container__item task_id">
              <label>ID: {{ el.taskId }}</label>
            </div>
            <div class="record_info_container__item title">
              <label>Date: {{ el.date }}</label>
            </div>
            <div
              class="record_info_container__item task_status"
              :class="`task_status_${el.taskState.toLowerCase()}`"
            >
              <label>{{ el.taskState }}</label>
            </div>
          </div>

          <div class="record_line_container__item buttons_container">
            <div
              class="buttons_container__item"
              :class="el.taskState !== 'OK' ? 'disable_button' : ''"
            >
              <button
                v-if="el.taskState === 'OK'"
                title="Скачать"
                @click="onDownload(event, el.src)"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H7.16667C7.625 2.16667 8 1.79167 8 1.33333C8 0.875 7.625 0.5 7.16667 0.5H2.16667C1.72464 0.5 1.30072 0.675595 0.988155 0.988155C0.675595 1.30072 0.5 1.72464 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8.83333C15.5 8.375 15.125 8 14.6667 8C14.2083 8 13.8333 8.375 13.8333 8.83333V13C13.8333 13.4583 13.4583 13.8333 13 13.8333ZM9.66667 1.33333C9.66667 1.79167 10.0417 2.16667 10.5 2.16667H12.6583L5.05 9.775C4.89419 9.93081 4.80665 10.1421 4.80665 10.3625C4.80665 10.5829 4.89419 10.7942 5.05 10.95C5.20581 11.1058 5.41714 11.1934 5.6375 11.1934C5.85786 11.1934 6.06919 11.1058 6.225 10.95L13.8333 3.34167V5.5C13.8333 5.95833 14.2083 6.33333 14.6667 6.33333C15.125 6.33333 15.5 5.95833 15.5 5.5V1.33333C15.5 0.875 15.125 0.5 14.6667 0.5H10.5C10.0417 0.5 9.66667 0.875 9.66667 1.33333Z"
                    fill="#010101"
                  />
                </svg>
              </button>
              <button v-else title="Скачать">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H7.16667C7.625 2.16667 8 1.79167 8 1.33333C8 0.875 7.625 0.5 7.16667 0.5H2.16667C1.72464 0.5 1.30072 0.675595 0.988155 0.988155C0.675595 1.30072 0.5 1.72464 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8.83333C15.5 8.375 15.125 8 14.6667 8C14.2083 8 13.8333 8.375 13.8333 8.83333V13C13.8333 13.4583 13.4583 13.8333 13 13.8333ZM9.66667 1.33333C9.66667 1.79167 10.0417 2.16667 10.5 2.16667H12.6583L5.05 9.775C4.89419 9.93081 4.80665 10.1421 4.80665 10.3625C4.80665 10.5829 4.89419 10.7942 5.05 10.95C5.20581 11.1058 5.41714 11.1934 5.6375 11.1934C5.85786 11.1934 6.06919 11.1058 6.225 10.95L13.8333 3.34167V5.5C13.8333 5.95833 14.2083 6.33333 14.6667 6.33333C15.125 6.33333 15.5 5.95833 15.5 5.5V1.33333C15.5 0.875 15.125 0.5 14.6667 0.5H10.5C10.0417 0.5 9.66667 0.875 9.66667 1.33333Z"
                    fill="#a1a3ab"
                  />
                </svg>
              </button>
            </div>
            <div class="buttons_container__item">
              <button title="Удалить" @click="onDelete(el.taskId)">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.83301 15.5C2.37467 15.5 1.98245 15.3369 1.65634 15.0108C1.32967 14.6842 1.16634 14.2917 1.16634 13.8333V3H0.333008V1.33333H4.49967V0.5H9.49967V1.33333H13.6663V3H12.833V13.8333C12.833 14.2917 12.67 14.6842 12.3438 15.0108C12.0172 15.3369 11.6247 15.5 11.1663 15.5H2.83301ZM11.1663 3H2.83301V13.8333H11.1663V3ZM4.49967 12.1667H6.16634V4.66667H4.49967V12.1667ZM7.83301 12.1667H9.49967V4.66667H7.83301V12.1667Z"
                    fill="#010101"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "action-bar": defineAsyncComponent(() =>
      import("@/components/ActionBar.vue")
    ),
  },
  props: {
    terminalId: {
      type: Number,
    },
    online: {
      type: Boolean,
    },
  },
  data() {
    return {
      records: [],
      showLoad: false,
      showNewRecord: false,
      actionBarData: {
        main: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
      },
    };
  },
  methods: {
    resetActionBar(type) {
      this.actionBarData[type] = {
        status: "",
        action: "",
        broadDescription: "",
        show: false,
      };
    },
    onDownload(event, url) {
      window.open(url, "_blank");
    },

    // delete record
    onDelete(taskId) {
      this.actionBarData.main = {
        status: "WAITING",
        action: "Удаление записи",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/task?taskId=${taskId}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy api obtained (delete data)", res);
          if (res.status === 200) {
            this.records = this.records.filter(
              (e) => e.taskId !== Number(taskId)
            );
            this.actionBarData.main.status = "OK";
            setTimeout(() => {
              this.resetActionBar("main");
            }, 4000);
            return
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = res.data.payload;
        })
        .catch((error) => {
          console.log("Error to delete record", error);
          if (error.request.status === 403) {
            this.actionBarData.main.status = "ERROR";
            (this.actionBarData.main.action = "Удаление screenshot"),
              (this.actionBarData.main.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = error.data;
        });
    },

    // create new record
    onCreateRecord() {
      this.actionBarData.main = {
        status: "WAITING",
        action: "Запрос записи",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/videorecord?terminalId=${this.terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          console.log(
            "Response from proxy api obtained (create records data). TaskId:",
            response.data.payload.taskId,
            response
          );
          this.$http_task
            .post(`${this.$backEndUrl}/v1/proxy/api`, {
              url: `/v2/task?taskId=${response.data.payload.taskId}`,
              method: "GET",
              payload: {},
              headers: {},
            })
            .then((taskResponse) => {
              console.log(
                `Response for taskId: ${response.data.payload.taskId} obtained. State ${taskResponse.data.payload.taskState}`
              );
              if (taskResponse.data.payload.taskState === "OK") {
                this.records.unshift({
                  date: taskResponse.data.payload.createDttm,
                  src: taskResponse.data.payload.taskData.result,
                  taskId: taskResponse.data.payload.taskId,
                  taskState: taskResponse.data.payload.taskState,
                });
                this.showNewRecord = true;
                this.actionBarData.main.status = "OK";
                setTimeout(() => {
                  this.resetActionBar("main");
                }, 4000);
                setTimeout(() => {
                  this.showNewRecord = false
                }, 4000);
                
                return;
              }
              this.actionBarData.main.status = "ERROR";
              this.actionBarData.main.broadDescription = taskResponse.data.payload;

            })
            .catch((error) => {
              this.actionBarData.main.status = "ERROR";
              this.actionBarData.main.broadDescription = error.data;
            });
        })
        .catch((error) => {
          console.log("Error to get screen", error);
          if (error.request.status === 403) {
            this.actionBarData.main.status = "ERROR";
            (this.actionBarData.main.action = "Запрос записи"),
              (this.actionBarData.main.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = error.data;
        });
    },

    // gets all records
    getRecords() {
      this.showLoad = true;
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/tasks?terminalId=${this.terminalId}&taskType=videorecord`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy api obtained (records data)", res);
          const data = [];
          res.data.payload.tasks.forEach((el) => {
            data.push({
              date: el.createDttm,
              src: el.taskData.result,
              taskId: el.taskId,
              taskState: el.taskState,
            });
          });
          this.records = data.sort((a, b) => {return Date.parse(b.date, "yyyy-MM-dd HH:mm:ss") - Date.parse(a.date, "yyyy-MM-dd HH:mm:ss")})
        })
        .catch((error) => {
          console.log("Error to get records data data", error);
        })
        .finally(() => {
          this.showLoad = false;
        });
    },
  },
  computed: {
    anyWaitingTask() {
      return this.actionBarData.main.status === "WAITING"
    },
  },

  mounted() {
    this.getRecords();
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
@import "../assets/styles/animations.less";

.terminal_record_container {
  display: flex;
  flex-direction: column;
  padding: 25px 25px;

  .not_found {
    display: flex;
    justify-content: center;
  }

  .buttons_container {
  display: flex;
  justify-content: space-between;
  .disable_button {
    button {
      &:hover {
        pointer-events: none;
      }
      &:active {
        pointer-events: none;
        box-shadow: none;

      }
    }
  }
  button {
      all: unset;
      padding: 5px 6px 5px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      cursor: pointer;
      &.disable {
        &:hover {
          background-color: unset;
          cursor: unset;
        }
      }
      &:hover {
        background-color: #4151b720;
        border-radius: 10px;
        transition: 0.5s;
      }
    }
}
}

.title_container {
  display: flex;
  justify-content: space-between;
}

.record_container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  overflow-y: scroll;
  max-height: 70vh;
  padding-right: 10px;
}

.record_line_container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #c9c9c9;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10.5px;
  padding: 5px;

  &.selected {
    background: #f1f6ff;
    border-radius: 5px;
    padding: 5px;
    border-bottom: unset
  }
}



.title_status_container {
  display: flex;
  align-items: center;

  .title {
    margin-right: 15px;
  }
}

.task_status_container {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  height: 40px;
  justify-content: center;
}


.record_info_container {
  display: flex;
  align-items: center;
  .record_info_container__item {
    margin-right: 20px;
    &.task_id {
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.title {
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }

  .task_status {
    padding: 3px 16px;
    height: 10px;
    border-radius: 20px;
    font-size: 10px;
    display: flex;
    align-items: center;
    color: #ffffff;

    &.task_status_pending {
      background-color: #4150b7;
    }
    &.task_status_waiting {
      background-color: #f0ad4e;
    }
    &.task_status_ok {
      background-color: #5bc367;
    }
    &.task_status_error {
      background-color: #f44c4b;
    }
    &.task_status_inprocess {
      background-color: #4150b7;
    }
  }
}
</style>
